import React, { FC } from "react";
import {
  getPaginationNodes,
  getPaginationData,
  getPaginationInitialData,
} from "@helpers/getPaginationNodes";
import { ReviewsQuery, ReviewsQueryVariables } from "@graphql/types";
import { useInfiniteQuery } from "react-query";
import { graphql } from "@helpers/graphql";
import { t } from "@lingui/macro";
import ReviewsBlock from "@components/common/ReviewsBlock";
import { Spinner } from "@components/ui/Spinner";
import LoadMore from "@components/common/Pagination/LoadMore";

interface ReviewsProps {
  initialData?: ReviewsQuery;
  filters?: ReviewsQueryVariables;
  title?: string;
}

export const Reviews: FC<ReviewsProps> = ({ initialData, filters, title }) => {
  const {
    isLoading,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["tour-reviews", filters],
    async ({ pageParam }) => {
      const res = await graphql.reviews({
        ...filters,
        after: pageParam,
      });

      return getPaginationData(res.reviews);
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialData: getPaginationInitialData(initialData?.reviews),
    }
  );

  return (
    <ReviewsBlock
      title={
        title ??
        t({
          id: "tour.block.reviews.title",
          message: "Reviews",
        })
      }
      columns="1"
      data={getPaginationNodes(data)}
    >
      {isLoading && <Spinner />}
      {!isLoading && data && (
        <LoadMore
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
    </ReviewsBlock>
  );
};
