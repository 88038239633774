import React from "react";
import { LinksSectionGroup } from "@components/common/LinksSection";
import { CityFooterFragment } from "../utils/getCityFooterData";
import { getLocationLinksByProductType } from "../utils/getLocationLinksByProductType";

interface SEOTopLinksProps {
  japanCities: CityFooterFragment[];
  otherCities: CityFooterFragment[];
}
export const SEOTopLinks = ({ japanCities, otherCities }: SEOTopLinksProps) => {
  const sections = getLocationLinksByProductType([
    ...japanCities.slice(0, 5),
    ...otherCities,
  ]);

  return (
    <div className="mt-20">
      <LinksSectionGroup links={sections} />
    </div>
  );
};
