import React, { FC, ReactNode } from "react";
import Icon from "../Icon";

const variants = {
  success: {
    color: "text-green-600",
    iconBgColor: "bg-green-100",
    fill: "#3B9B4E",
  },
  warning: {
    color: "text-yellow-600",
    iconBgColor: "bg-yellow-100",
    fill: "#FFAF2C",
  },
  info: {
    color: "text-blue-600",
    iconBgColor: "bg-blue-100",
    fill: "#2470C9",
  },
  error: {
    color: "text-red-600",
    iconBgColor: "bg-red-100",
    fill: "#CF242A",
  },
};

interface NotificationProps {
  children?: ReactNode;
  variant: "success" | "info" | "error" | "warning";
  title: string;
}

const Notification: FC<NotificationProps> = ({
  children,
  variant = "warning",
  title,
}) => {
  const styles = variants[variant];
  return (
    <div className={`${styles.iconBgColor} flex items-center rounded`}>
      <div className="p-2">
        <Icon
          icon={variant}
          className="text-white h-9 w-9"
          size="md"
          fill={styles.fill}
        />
      </div>
      <div className="items-center p-6 w-full">
        <h4 className={`${styles.color} text-lg`}>{title}</h4>
        {children ? <div className="text-black mt-2">{children}</div> : null}
      </div>
    </div>
  );
};

export default Notification;
