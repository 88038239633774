import React, { FC, useEffect } from "react";
import { Trans, t } from "@lingui/macro";
import Link from "next/link";
import {
  AuthorType,
  City,
  Country,
  PostOrderBy,
  PostsQuery,
  ReviewsQuery,
  StatsPayload,
  Supplier,
  Tour,
  TourCardFragment,
} from "@graphql/types";
import { CACHE_TIME_MORE, SITE_NAME } from "@constants";
import { GetStaticProps } from "next";
import { getMetaDataDescription } from "@helpers/getMetaDataDescription";
import Head from "@components/common/Head";
import SupplierApplyAlert from "@components/common/SupplierApplyAlert/SupplierApplyAlert";
import SupplierUpdateCalendarAlert from "@components/common/SupplierUpdateCalendarAlert";
import SearchMicroFormat from "@components/common/MicroFormat/SearchMicroFormat";
import ProductMicroFormat from "@components/common/MicroFormat/ProductMicroFormat";
import GlobalSearch from "@components/common/GlobalSearch";
import Hero from "@components/common/Hero/Hero";
import Stats from "@components/common/Stats";
import { Container } from "@components/ui/Container";
import CountriesBlock from "@components/common/LocationBlock/CountriesBlock";
import { ButtonLink } from "@components/ui/Button";
import SuppliersBlock from "@components/common/SupplierBlock";
import ToursBlock from "@components/common/ToursBlock";
import { Posts } from "@components/pages/SupplierPage/Posts";
import { Reviews } from "@components/pages/TourPage/Reviews";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { signUpLastNameWarning } from "@helpers/signUpLastNameWarning";
import { graphql } from "@helpers/graphql";
import { SEOTopLinks } from "@components/common/SEO/SEOTopLinks";
import { CitiesBlock } from "@components/common/LocationBlock/CitiesBlock";
import { ROUTES } from "@root/routes";

const getPostsVariables = (postOrderType: PostOrderBy) => {
  return {
    first: 4,
    filters: {
      ORDER_BY: postOrderType,
      AUTHOR: [AuthorType.Company],
    },
  };
};

const getReviewsVariables = () => {
  return {
    first: 3,
    filters: {},
  };
};

export const getStaticProps: GetStaticProps = async () => {
  //1. Get all the data
  const data = await graphql.topPage();

  //2. Get reviews
  const reviews = await graphql.reviewsInTopPage(getReviewsVariables());

  //3. Get latest articles
  const latestArticles = await graphql.posts(
    getPostsVariables(PostOrderBy.CreatedAtDesc)
  );

  return {
    props: {
      initialData: {
        tours: data.topPage.tours,
        newTours: data.topPage.newTours,
        cars: data.topPage.cars,
        countries: data.topPage.countries,
        citiesJapan: data.topPage.citiesJapan,
        citiesOthers: data.topPage.citiesOthers,
        suppliers: data.topPage.suppliers,
        newSuppliers: data.topPage.newSuppliers,
        stats: data.topPage.stats,
        latestArticles,
        reviews,
      },
    },
    revalidate: CACHE_TIME_MORE, //  5 hours cache
  };
};

type Props = {
  initialData: {
    tours?: Tour[];
    newTours?: Tour[];
    cars?: Tour[];
    countries?: Country[];
    citiesJapan?: City[];
    citiesOthers?: City[];
    suppliers?: Supplier[];
    newSuppliers?: Supplier[];
    stats?: StatsPayload;
    latestArticles?: PostsQuery;
    reviews?: ReviewsQuery;
  };
};

const Page: FC<Props> = ({ initialData }) => {
  useEffect(() => {
    signUpLastNameWarning();
  }, []);

  return (
    <>
      <Head
        title={t({
          id: "home.seo.title",
          message: `${SITE_NAME} - Private Tours & Local Tour Guides`,
        })}
        description={getMetaDataDescription(
          t({
            id: "home.seo.description",
          })
        )}
        titleTemplate="%s"
        canonical="/"
      />
      <SupplierApplyAlert />
      <SupplierUpdateCalendarAlert />
      <meta
        name="description"
        content="The best tours and activities With a real local of your choice The option to personalize your tour Just contact your favorite local Only private tours! So no group tours with strangers"
      />
      <SearchMicroFormat />
      <ProductMicroFormat
        productID="top"
        name={SITE_NAME}
        image={[
          `${process.env.NEXT_PUBLIC_DOMAIN}/images/7YXNVVv38Sm9OSqcJX_Ymyq858IjRCjokonSbPPmHuM/f:jpg/h:628/w:1200/plain/gowithguide/assets/header/hero-bg-2.jpg`,
          `${process.env.NEXT_PUBLIC_DOMAIN}/icon/apple-icon-114x114.png`,
        ]}
        description={t({ id: "home.seo.description" })}
        reviewCount={initialData?.stats?.reviewsCount}
      />
      <div style={{ background: "", alignContent: "" }}></div>

      <Hero
        placeholder="/img/hero-bg-pixelated.avif"
        src="/img/hero-bg-desktop.avif"
        alt="hero image home page"
        className="h-hero-home-mobile lg:h-hero-home-mobile-desktop"
        title={t({
          id: "home.hero.h1",
          message: `Customizable Private Tours with Local Tour Guides`,
        })}
        subtitle={t({
          id: "home.hero.h2",
          message: `Choose Local Tour Guides at the Destination of Your Choice. Simply Message Them To Personalize Your Tour!`,
        })}
      >
        <GlobalSearch className="mt-4" />
      </Hero>
      <Stats />
      <Container className="py-10 pb-10 md:py-24 md:pt-0">
        {/* Popular Cities */}
        <CitiesBlock
          title={t({
            id: "home.block.popular-japan-destinations.title",
            message: "Popular Japan Tour Destinations",
          })}
          data={initialData.citiesJapan}
          className="my-10"
        >
          <Link href={getAbsoluteUrl("/japan/destinations")} passHref>
            <ButtonLink variant="default-outline">
              <Trans id="home.block.popular-japan-cities.button">
                View All Japan Tour Destinations
              </Trans>
            </ButtonLink>
          </Link>
        </CitiesBlock>
        {/* Popular Cities */}
        <CitiesBlock
          title={t({
            id: "home.block.other-popular-destinations.title",
            message: "Other Popular Tour Destinations",
          })}
          data={initialData.citiesOthers}
          className="my-10"
        />
        {/* Popular Countries */}
        <CountriesBlock
          title={t({
            id: "home.block.popular-countries.title",
            message: "Popular Tour Countries",
          })}
          data={initialData.countries}
        >
          <Link href={getAbsoluteUrl("/destinations")} passHref>
            <ButtonLink variant="default-outline">
              <Trans id="home.block.popular-countries.button">
                See All Tour Countries
              </Trans>
            </ButtonLink>
          </Link>
        </CountriesBlock>

        {/* Popular Tour Guides */}
        <SuppliersBlock
          title={t({
            id: "home.block.popular-guides.title",
            message: "Popular Tour Guides",
          })}
          data={initialData.suppliers}
          className="mb-10"
        />

        {/* New Tour Guides */}
        <SuppliersBlock
          title={t({
            id: "home.block.new-guides.title",
            message: "New Tour Guides",
          })}
          data={initialData.newSuppliers}
          className="mb-10"
        />
        {/* Popular Private Tours */}
        <ToursBlock
          title={t({
            id: "home.block.popular-tours.title",
            message: "Popular Private Tours",
          })}
          data={initialData.tours as TourCardFragment[]}
        />

        {/* New Private Tours */}
        <ToursBlock
          title={t({
            id: "home.block.new-tours.title",
            message: "New Private Tours",
          })}
          data={initialData.newTours as TourCardFragment[]}
        />

        {/* Popular Private Cars */}
        <ToursBlock
          title={t({
            id: "home.block.private-car-tours.title",
            message: "Popular Private Cars",
          })}
          data={initialData.cars as TourCardFragment[]}
        />

        {/* Reviews */}
        <div id="reviews">
          <Reviews
            initialData={initialData?.reviews}
            filters={getReviewsVariables()}
          />
        </div>

        {/* Latest  Articles */}
        {initialData.latestArticles?.posts?.edges &&
          initialData.latestArticles?.posts?.edges.length > 0 && (
            <>
              <Posts
                initialData={initialData?.latestArticles}
                filters={getPostsVariables(PostOrderBy.CreatedAtDesc)}
                showUser={true}
              />

              <div className="flex justify-center">
                <Link href={ROUTES.blog.index()} passHref>
                  <ButtonLink
                    variant="default-outline"
                    rel="noopener noreferrer"
                  >
                    <Trans id="blogs.view.more-new-articles">
                      View More New Articles
                    </Trans>
                  </ButtonLink>
                </Link>
              </div>
            </>
          )}

        {/* SEO Links */}
        {initialData.citiesJapan && initialData.citiesOthers && (
          <SEOTopLinks
            japanCities={initialData.citiesJapan}
            otherCities={initialData.citiesOthers}
          />
        )}
      </Container>
    </>
  );
};

export default Page;
