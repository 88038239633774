import "react-toastify/dist/ReactToastify.css";
import Notification from "@components/ui/Notification";
import React from "react";
import { toast } from "react-toastify";

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
});

export const useNotification = () => {
  const notification = {
    success: (title: string, message?: string) => {
      toast(
        <Notification title={title} variant="success">
          {message}
        </Notification>
      );
    },
    warning: (title: string, message?: string) => {
      toast(
        <Notification title={title} variant="warning">
          {message}
        </Notification>
      );
    },
    info: (title: string, message?: string) => {
      toast(
        <Notification title={title} variant="info">
          {message}
        </Notification>
      );
    },
    error: (title: string, message?: string) => {
      toast(
        <Notification title={title} variant="error">
          {message}
        </Notification>
      );
    },
  };
  return notification;
};
