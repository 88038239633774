import { Link, LinksSection } from "@components/common/LinksSection";
import { CityFooterFragment } from "./getCityFooterData";

export const getLocationLinksByProductType = (
  locations: CityFooterFragment[]
): LinksSection[] => {
  const sections: LinksSection[] = [];

  const supplierLinks: Link[] = [];
  const toursLinks: Link[] = [];
  const carsLinks: Link[] = [];
  const onlineExperiencesLinks: Link[] = [];

  for (const location of locations) {
    if (location.hasPrivateTours) {
      toursLinks.push({
        title: `${location.name} Private Tours`,
        url: `${location.url}/tours`,
      });
    }

    if (location.hasSuppliers) {
      supplierLinks.push({
        title: `${location.name} Tour Guides`,
        url: `${location.url}/guides`,
      });
    }

    if (location.hasTransportationTours) {
      carsLinks.push({
        title: `${location.name} Private Car Tours`,
        url: `${location.url}/cars`,
      });
    }

    if (location.hasOnlineTours) {
      onlineExperiencesLinks.push({
        title: `${location.name} Virtual Tours`,
        url: `${location.url}/online-experiences`,
      });
    }
  }

  if (supplierLinks.length > 0) {
    sections.push({
      title: "Popular Tour Guides Destinations",
      links: supplierLinks,
    });
  }

  if (toursLinks.length > 0) {
    sections.push({
      title: "Popular Private Tours Destinations",
      links: toursLinks,
    });
  }

  if (carsLinks.length > 0) {
    sections.push({
      title: "Popular Private Car Tours Destinations",
      links: carsLinks,
    });
  }

  if (onlineExperiencesLinks.length > 0) {
    sections.push({
      title: "Popular Virtual Tours Destinations",
      links: onlineExperiencesLinks,
    });
  }

  return sections;
};
