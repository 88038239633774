import { t } from "@lingui/macro";
import cookies from "./cookies";
import { useNotification } from "./hooks/useNotification";

export const signUpLastNameWarning = () => {
  const notification = useNotification();

  // show notification if lastName is missing in api response
  if (cookies().get("isLastNameMissing") === "true") {
    notification.warning(
      t({
        id: "auth.missing.field.lastname.message",
        message:
          'Our website requires all users to have data in the "First name" and "Last name" fields. Your Google/Facebook login credentials only provided one but not the other, so we created your GoWithGuide account with the same name and last name. If you want to update your info, please do so from Account -> Personal Info.',
      })
    );
    cookies().remove("isLastNameMissing");
  }
};
