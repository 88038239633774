import { SuppliersQueryVariables, SupplierCardFragment } from "@graphql/types";
import classNames from "classnames";
import React, { FC, HTMLAttributes } from "react";
import { t } from "@lingui/macro";
import { Title } from "@components/ui/Title";
import SupplierCard from "../SupplierCard";

interface Props extends SuppliersQueryVariables {
  title?: string;
  title_link?: string;
  data?: SupplierCardFragment[];
  columns?: "4" | "3";
  className: HTMLAttributes<HTMLDivElement>["className"];
}

const SuppliersBlock: FC<Props> = ({
  title,
  data,
  children,
  columns = "4",
  title_link,
  className,
}) => {
  if (!data?.length) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex">
        <div>
          {title &&
            (title_link ? (
              <a
                title={t({
                  id: "common.link.title",
                  message: "GoWithGuide - Private Tours & Local Tour Guides",
                })}
                href={title_link}
                target="_blank"
                rel="noreferrer"
              >
                <Title as="h2" size="md" divider>
                  {title}
                </Title>
              </a>
            ) : (
              <Title as="h2" size="md" divider>
                {title}
              </Title>
            ))}
        </div>
      </div>
      <div
        className={classNames(
          "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4",
          {
            "lg:grid-cols-3": columns === "3",
            "lg:grid-cols-4": columns === "4",
          }
        )}
      >
        {data && data.map((node) => <SupplierCard key={node.id} {...node} />)}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};

export default SuppliersBlock;
